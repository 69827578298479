import React from "react"

import Layout from "../components/Layout"
import HelmetWrapper from "../components/HelmetWrapper"
import Hero from "../components/Hero"
import { Markup } from "interweave"
import headerHero from "../assets/images/header_aboutus.png"

import "./bod.scss"

const pageDescription =
  "Learn about our team of professionals with deep experience and a proven track record of success in developing and commercializing novel treatments for patients."
const pageKeywords = "harmony biosciences"

class BOD extends React.Component {
  render() {
    const { bod } = this.props?.pageContext
    const photo = bod.photo
    return (
      <Layout>
        <div id="our-leadership" className="bod-leadership-section">
          <HelmetWrapper
            pageTitle="Our Leadership"
            pageDescription={pageDescription}
            pageKeywords={pageKeywords}
            slug={`/about-us/senior-leadership/${bod.slug}`}
          />
          <Hero bg={headerHero}>
            <h1>{bod?.name}</h1>
          </Hero>

          <div className={`bod-leader-container card-container`}>
            <img src={photo} style={{'display':'none'}} alt={bod.name} />
            <div className="card-text-container">
              <p>
                <strong>{bod.name}</strong>
              </p>
              <p>
                <Markup content={bod.title} />
              </p>
              <div
                className="bod-bio-container"
                dangerouslySetInnerHTML={{
                  __html: bod.bio,
                }}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BOD
